<template>

    <div class="page-wrap">
      <div class="menu-bar">
        <img class="menu-bar-icon" src="../assets/menu-bar-icon.png">
        <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>

        <div class="menu-btn" :class="menuBtnClass" @click="menuBtn">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </div>

      <div class="menu-close-panel" v-if="menuClass=='menu-open'" @click="menuBtn"></div>

      <div class="menu" :class="menuClass">
        <div class="btn" @click="menuAnchor(1)">
          <div class="ch">歷年故事</div>
          <div class="en">Our Story</div>
        </div>
        <div class="line"></div>
        <div class="btn" @click="menuAnchor(10)">
          <div class="ch">三大農工技術</div>
          <div class="en">3 Major Technologies</div>
        </div>
        <div class="line"></div>
        <div class="btn" @click="menuAnchor(13)">
          <div class="ch">二林鎮的釀酒葡萄</div>
          <div class="en">Wine Grapes In Erlin Township</div>
        </div>
        <div class="line"></div>
        <div class="btn" @click="menuAnchor(16)">
          <div class="ch">佳釀葡萄品種</div>
          <div class="en">Grape Variety</div>
        </div>
        <div class="line" ></div>
        <div class="btn" @click="menuAnchor(19)">
          <div class="ch">獲獎紀錄</div>
          <div class="en">Award Record</div>
        </div>
        <div class="line" ></div>
        <div class="btn" @click="menuAnchor(20)">
          <div class="ch">聯絡我們</div>
          <div class="en">CONTACT US</div>
        </div>
      </div>

      <div class="all-year-page" :class="allYearClass" @click="allYearBtn(false)" >
        <img src="../assets/all-year-page.png">
      </div>



      <div class="page" :class="pageClass">
        <!-- 1 -->
        <div class="page-content" id="page-1"  v-if="curPage==1 || curPage==2">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[0][0]'>1964</span></div>
          <div class="sake-div" v-if='curPage==1'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[0][1]"><span class="line"></span>接觸葡萄的栽種</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==1"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==1"></div>
        </div>

        <!-- 2 -->
        <div class="page-content" id="page-2" v-if="curPage==2 || curPage==3">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[1][0]'>1983</span></div>
          <div class="sake-div" v-if='curPage==2'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[1][1]"><span class="line"></span>接受菸葉試驗所之妥託進行多品種葡萄移植</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==2"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==2"></div>
        </div>

        <!-- 3 -->
        <div class="page-content" id="page-3" v-if="curPage==3 || curPage==4">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[2][0]'>1997</span></div>
          <div class="sake-div" v-if='curPage==3'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[2][1]"><span class="line"></span>公賣局停止契作收購</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==3"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==3"></div>
        </div>

        <!-- 4 -->
        <div class="page-content" id="page-4" v-if="curPage==4 || curPage==5">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[3][0]'>2000</span></div>
          <div class="sake-div" v-if='curPage==4'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[3][1]"><span class="line"></span>負笈前往加拿大學習釀酒</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==4"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==4"></div>
        </div>

        <!-- 5 -->
        <div class="page-content" id="page-5" v-if="curPage==5 || curPage==6">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[4][0]'>2003</span></div>
          <div class="sake-div" v-if='curPage==5'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[4][1]"><span class="line"></span>於彰化正式成立鵬群頂酒莊</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==5"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==5"></div>
        </div>

        <!-- 6 -->
        <div class="page-content" id="page-6" v-if="curPage==6 || curPage==7">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[5][0]'>2006</span></div>
          <div class="sake-div" v-if='curPage==6'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[5][1]"><span class="line"></span>至日本長野酒莊見習參訪</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==6"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==6"></div>
        </div>

        <!-- 7 -->
        <div class="page-content" id="page-7" v-if="curPage==7 || curPage==8">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[6][0]'>2011</span></div>
          <div class="sake-div" v-if='curPage==7'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[6][1]"><span class="line"></span>使用「台中一號葡萄」釀造現釀酒</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==7"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==7"></div>
        </div>

        <!-- 8 -->
        <div class="page-content" id="page-8" v-if="curPage==8 || curPage==9">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[7][0]'>2012</span></div>
          <div class="sake-div" v-if='curPage==8'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[7][1]"><span class="line"></span>榮獲紫晶盃葡萄酒評鑑 白葡萄組第三名</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==8"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==8"></div>
        </div>

        <!-- 9 -->
        <div class="page-content" id="page-9" v-if="curPage==9 || curPage==10">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[8][0]'>2013</span></div>
          <div class="sake-div" v-if='curPage==9'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[8][1]"><span class="line"></span>榮獲紫晶盃葡萄酒評鑑 白葡萄酒組 - 冠軍 - 醺白葡萄酒</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==9"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==9"></div>
        </div>

        <!-- 10 -->
        <div class="page-content" id="page-10" v-if="curPage==10 || curPage==11">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="year"><span :class='pageFade[9][0]'>2016</span></div>
          <div class="sake-div" v-if='curPage==10'><img src="../assets/sake.png"></div>
          <div class="content-text" :class="pageFade[9][1]"><span class="line"></span>榮獲紫晶盃葡萄酒評鑑 白葡萄酒組 - 冠軍 - 翡翠白葡萄酒</div>
          <div class="all-year-btn" @click="allYearBtn(true)" v-if="curPage==10"></div>
          <div class="arrow" @click="arrowBtn2(10)" v-if="curPage==10"></div>
        </div>

        <!-- 11 -->
        <div class="page-content" id="page-11" v-if="curPage==11 || curPage==12">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="sake-div" v-if='curPage==11'><img src="../assets/sake.png"></div>

          <div class="major-tech-content " :class='pageFade[10][0]'>
            <div class="title">
              <div>
                <div>3</div>
                <div>
                  <div>Major</div>
                  <div>Technologies</div>
                </div>
              </div>
              <div class="subtitle">導入三大農工技術</div>
              <div class="line"></div>
            </div>

            <div class="content">
              <p>1.經濟作物可調適性補光模組</p>
              <p>透過全息投影，將果園藤架縮小放置於展示箱，搭配投影展示可調適性補光模組可切換2種光譜比例變以及3段光強度變化之功能，以及可調適性補光模與傳統螺旋燈具葡萄園光照場域試驗，相較傳統光源減少補光耗電量50%的效果展示。</p>
            </div>
          </div>

          <div class="content-img " :class='pageFade[10][1]'>
            <img src="../assets/p1.png">
          </div>
          <div class="arrow" @click="arrowBtn2(13)" v-if="curPage==11"></div>
          <div class="img-dot-div" v-if="curPage==11">
            <div class="dot selected"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>

        <!-- 12 -->
        <div class="page-content" id="page-12" v-if="curPage==12 || curPage==13">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="sake-div" v-if='curPage==12'><img src="../assets/sake.png"></div>

          <div class="major-tech-content ">
            <div class="title" :class="pageFade[11][2]">
              <div>
                <div>3</div>
                <div>
                  <div>Major</div>
                  <div>Technologies</div>
                </div>
              </div>
              <div class="subtitle">導入三大農工技術</div>
              <div class="line"></div>
            </div>

            <div class="content" :class='pageFade[11][0]'>
              <p>2.在地循環利用防治資材開發</p>
              <p>透過全息投影，將數位生產預控生態資材碳化系統作成模型放置於展示箱中，搭配投影展示以生物炭醋液以及天然資材，針對葡萄之病蟲害防治，調配物製劑以及透過建立標準操作參數，穩定生物炭生(>20%)和天然醋液生產量(>25%)，與相關初級資材率總計大於50%。</p>
            </div>
          </div>

          <div class="content-img " :class='pageFade[11][1]'>
            <img src="../assets/p2.png">
          </div>
          <div class="arrow" @click="arrowBtn2(13)" v-if="curPage==12"></div>
          <div class="img-dot-div" v-if="curPage==12">
            <div class="dot "></div>
            <div class="dot selected"></div>
            <div class="dot"></div>
          </div>
        </div>

        <!-- 13 -->
        <div class="page-content" id="page-13" v-if="curPage==13 || curPage==14" :class='pageFade[12][2]'>
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="sake-div" v-if='curPage==13' ><img src="../assets/sake.png"></div>

          <div class="major-tech-content" :class='pageFade[12][3]'>
            <div class="title" >
              <div>
                <div>3</div>
                <div>
                  <div>Major</div>
                  <div>Technologies</div>
                </div>
              </div>
              <div class="subtitle">導入三大農工技術</div>
              <div class="line"></div>
            </div>

            <div class="content" :class='pageFade[12][0]'>
              <p>3.重型適應地形協作載具平台</p>
              <p>透過全息投影，適合葡萄園地形之重載輪式協作載具作模型放置於將果園藤架縮展示箱內，搭配投影展示作載具可與Modbus及CANbus通訊，並可模組化擴充功，大於300kg的大載重功能展示、運作於畦溝全地形小迴轉半徑。以及遙控式舉背式噴藥，與傳統相比，少30%以上噴藥量。</p>
            </div>
          </div>

          <div class="content-img " :class='pageFade[12][1]'>
            <img src="../assets/p3.png">
          </div>
          <div class="arrow" @click="arrowBtn2(13)" v-if="curPage==13"></div>
          <div class="img-dot-div" v-if="curPage==13">
            <div class="dot "></div>
            <div class="dot "></div>
            <div class="dot selected"></div>
          </div>
        </div>

        <!-- 14 -->
        <div class="page-content" id="page-14" v-if="curPage==14 || curPage==15">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="sake-div" v-if='curPage==14'><img src="../assets/sake.png"></div>

          <div class="wine-content" :class="pageFade[13][2]">
            <div class="title">
              <div>
                  <div>Wine Grapes</div>
                  <div>In Erlin Township</div>
              </div>
              <div class="subtitle" style="margin-top: 1.4%">二林鎮的釀酒葡萄</div>
              <div class="line"></div>
            </div>

            <div class="content" :class='pageFade[13][0]' v-if="curPage==14">
              <p>二林鎮是釀酒用葡萄的大產地，民國54年起為了因應公賣局釀酒所需，開始大量栽植釀酒用的金香、黑后等葡萄品種。</p>
              <p>現今釀酒葡萄種植面積為200公頃，地方酒莊19間，是全台合法民間酒莊密度、同質性最高的農村葡萄酒莊地區。</p>
            </div>


          </div>

          <div class="content-img grape-img" :class='pageFade[13][1]' v-if="curPage==14">
            <img src="../assets/p4.png">
          </div>
          <div class="arrow" @click="arrowBtn2(16)" v-if="curPage==14"></div>
          <div class="img-dot-div" v-if="curPage==14">
            <div class="dot selected"></div>
            <div class="dot "></div>
            <div class="dot "></div>
          </div>
        </div>

        <!-- 15 -->
        <div class="page-content" id="page-15" v-if="curPage==15 || curPage==16" >
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="sake-div" v-if='curPage==15'><img src="../assets/sake.png"></div>

          <div class="wine-content" :class="pageFade[14][2]">
            <div class="title">
              <div>
                <div>Wine Grapes</div>
                <div>In Erlin Township</div>
              </div>
              <div class="subtitle">二林鎮的釀酒葡萄</div>
              <div class="line content-text-fade-in" ></div>
            </div>
            <div class="content" :class='pageFade[14][0]' style="width: 75%">
              <p style="font-family: NotoSansCJKtc-Bold; font-size: 1.15vw">86年釀酒葡萄的轉類年</p>
              <p>釀酒葡萄的契作終止</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>葡萄樹全部砍除、改種其他農作。</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>小部份保留葡萄樹等待機會翻身。</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>少數改種鮮食巨峰葡萄。</p>

              <p style="font-family: NotoSansCJKtc-Bold; font-size: 1.15vw; margin-top: 6%">90年酒莊的興起</p>
              <p>民國90年政府開放民間製酒</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>本人於民國91年底即向財政部申請酒莊。</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>釀酒葡萄又可延續生機。</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>台中區改良場適時推出新品種的栽培讓酒莊增加曝光率與商機。</p>
            </div>
          </div>

          <div class="content-img grape-img" :class='pageFade[14][1]'>
            <img src="../assets/p5.png">
          </div>
          <div class="arrow" @click="arrowBtn2(16)" v-if="curPage==15"></div>
          <div class="img-dot-div" v-if="curPage==15">
            <div class="dot "></div>
            <div class="dot selected"></div>
            <div class="dot "></div>
          </div>
        </div>

        <!--16-->
        <div class="page-content" id="page-16" v-if="curPage==16 || curPage==17" :class="pageFade[15][2]">
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="sake-div" v-if='curPage==16' :class="pageFade[15][3]"><img src="../assets/sake.png"></div>

          <div class="wine-content" :class="pageFade[15][2]">
            <div class="title">
              <div>
                <div>Wine Grapes</div>
                <div>In Erlin Township</div>
              </div>
              <div class="subtitle">二林鎮的釀酒葡萄</div>
              <div class="line content-text-fade-in"></div>
            </div>
            <div class="content" :class='pageFade[15][0]' style="width: 75%">
              <p style="font-family: NotoSansCJKtc-Bold; font-size: 1.15vw">葡萄的千秋大業</p>
              <p>如何栽培好你的葡萄</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>不輕易放棄任何葡萄新的資訊，與培訓進修的機會。</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>保留老師發給的資料或講義，勿成為明天資源回收品。</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>用心仔細觀察每一階段的生長狀況，每一栽培管理過程都要有詳細紀錄。</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>遇有問題要盡快請教改良場的專家幫忙解決。</p>
            </div>


          </div>

          <div class="content-img grape-img" :class='pageFade[15][1]'>
            <img src="../assets/p6.png" style="height: 98%">
          </div>
          <img class='table-1' src="../assets/p7.png">
          <img class='table-2' src="../assets/p8.png">
          <div class="arrow" @click="arrowBtn2(16)" v-if="curPage==16"></div>
          <div class="img-dot-div" v-if="curPage==16">
            <div class="dot "></div>
            <div class="dot "></div>
            <div class="dot selected"></div>
          </div>
        </div>

        <!--17-->
        <div class="page-content" id="page-17" v-if="curPage==17 || curPage==18" >
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="sake-div" v-if='curPage==17'><img src="../assets/sake.png"></div>

          <div class="wine-content" :class="pageFade[16][2]" style="padding-top: 8%">
            <div class="title">
              <div>
                <div>Peng Chiun Ding</div>
                <div>Grape Variety</div>
              </div>
              <div class="subtitle">培育佳釀的葡萄品種</div>
              <div class="line " ></div>
            </div>
            <div class="content" :class='pageFade[16][0]' style="width: 80%; ">
              <p style="font-family: NotoSansCJKtc-Bold; font-size: 1.15vw">目前酒莊釀酒用葡萄有兩大品種</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>紅酒用品種 - 黑后</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>白酒用品種 - 金香</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>台中區改良場推出</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>台中一號(真香葡萄) / 台中二號(紅酒葡萄)</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>其他自園內精選葡萄品種</p>

              <p style="font-family: NotoSansCJKtc-Bold; font-size: 1.15vw; margin-top: 6%">酒莊葡萄園的介紹</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>葡萄園的種植總面積1.5公頃</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>紅酒用葡萄種植0.6公頃</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>白酒用葡萄種植0.45公頃</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>其他品種葡萄種植0.45公頃</p>
            </div>


          </div>

          <div class="content-img" :class='pageFade[16][1]'>
            <img src="../assets/p9.png">
          </div>
          <div class="arrow" @click="arrowBtn2(19)" v-if="curPage==17"></div>
          <div class="img-dot-div" v-if="curPage==17">
            <div class="dot selected"></div>
            <div class="dot "></div>
            <div class="dot "></div>
          </div>
        </div>

        <!--18-->
        <div class="page-content" id="page-18" v-if="curPage==18 || curPage==19" >
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->
          <div class="sake-div"  :class="pageFade[17][3]"><img src="../assets/sake.png"></div>

          <div class="wine-content" :class="pageFade[17][2]" style="padding-top: 8%">
            <div class="title">
              <div>
                <div>Peng Chiun Ding</div>
                <div>Grape Variety</div>
              </div>
              <div class="subtitle" style="margin-top: 0.6%">培育佳釀的葡萄品種</div>
              <div class="line" style="margin-top: 2.4%"></div>
            </div>
            <div class="content" :class='pageFade[17][0]' style="width: 33%; margin-top: 2.5%">
              <p>如翡翠青綠靈透的葡萄釀造出的美酒</p>
              <p>由呂副總統親自寫下，至此誕生了「鵬群頂翡翠白酒」</p>
              <p style="margin-top: 6%">酒莊率先採取半有機方式栽種，並以細膩的溫度控制來維持發酵的釀造細節，除此之外更依照葡萄園中的品種特性、條件先個別釀造成酒，再進行勾兌作業，讓酒品口感、餘韻的豐富度獨一無二。在前彰化縣長翁金珠的促成、呂副總統品酒後有感，提筆寫下「翡翠白酒」！讓此款編号1502的葡萄酒從此正名，並在後續參與各項比賽、銷售、口碑上屢創佳績。</p>

            </div>
          </div>

          <div class="content-img" :class='pageFade[17][1]'>
            <img src="../assets/p11.png">
          </div>
          <div class="arrow" @click="arrowBtn2(19)" v-if="curPage==18"></div>
          <div class="img-dot-div" v-if="curPage==18">
            <div class="dot "></div>
            <div class="dot selected"></div>
            <div class="dot "></div>
          </div>
        </div>

        <!--19-->
        <div class="page-content" id="page-19" v-if="curPage==19 || curPage==20" >
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->

          <div class="wine-content" :class="pageFade[18][2]" style="padding-top: 13%">
            <div class="title">
              <div>
                <div>Peng Chiun Ding</div>
                <div>Grape Variety</div>
              </div>
              <div class="subtitle">培育佳釀的葡萄品種</div>
              <div class="line" style="margin-top: 7.5%"></div>
            </div>
            <div class="content" :class='pageFade[18][0]' style="width: 80%; margin-top: 12% ">
              <p style="font-family: NotoSansCJKtc-Bold; font-size: 1.15vw">現有酒類產品</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>卡多納白葡萄酒</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>百香果酒</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>仙蜜玫瑰紅酒</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>卡娜伊紅酒</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>漢妮芬紅酒</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>翡翠白葡萄酒</p>
              <p><span style="font-size: 0.1vw">● &nbsp;</span>醺白葡萄酒</p>
            </div>


          </div>

          <div class="content-img" :class='pageFade[18][1]' style="top: 15%; right: 5%">
            <img src="../assets/p10.png">
          </div>
          <div class="arrow" @click="arrowBtn2(19)" v-if="curPage==19"></div>
          <div class="img-dot-div" v-if="curPage==19">
            <div class="dot "></div>
            <div class="dot "></div>
            <div class="dot selected"></div>
          </div>
        </div>

        <!--20-->
        <div class="page-content" id="page-20" v-if="curPage==20 || curPage==21" >
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->

          <div class="wine-content" :class="pageFade[19][2]" style="padding-top: 4.6%">
            <div class="title"  :class='pageFade[19][0]'>
              <div>
                <div>Peng Chiun Ding</div>
                <div>Award Record</div>
              </div>
              <div class="subtitle">收穫評審挑惕肯定</div>
              <div class="line" style="margin-top: 5%"></div>
            </div>
            <div class="content" :class='pageFade[19][0]' style="width: 100%; margin-top: 7% ">
              <p>本品於97年參加紫晶盃白葡萄酒組比賽獲得第一名。</p>
              <p>本酒品於100年獲得財政部優良酒品認證。</p>
              <p>本酒品於110年開始外銷日本網路的銷售。</p>

              <p style="font-family: NotoSansCJKtc-Bold; font-size: 1.15vw; margin-top: 6%">獲獎記錄</p>
              <p>2005 - 葡萄美酒酒品大賞白葡萄酒組 - 頂級白葡萄酒 -&nbsp;<span style="font-family: NotoSansCJKtc-Bold">金牌</span></p>
              <p>2008 - 紫晶盃葡萄酒評鑑白葡萄酒組 - 卡多納白葡萄酒 -&nbsp;<span style="font-family: NotoSansCJKtc-Bold">冠軍</span></p>
              <p>2008 - 紫晶盃葡萄酒評鑑紅葡萄乾酒組 - 漢妮芬紅酒 -&nbsp;<span style="font-family: NotoSansCJKtc-Bold">亞軍</span></p>
              <p>2010 - 紫晶盃葡萄酒評鑑紅葡萄乾酒組 - 卡娜伊紅酒 -&nbsp;<span style="font-family: NotoSansCJKtc-Bold">季軍</span></p>
              <p>2013 - 紫晶盃葡萄酒評鑑白葡萄酒組 - 醺白葡萄酒 -&nbsp;<span style="font-family: NotoSansCJKtc-Bold">冠軍</span></p>
              <p>2016 - 紫晶盃葡萄酒評鑑白葡萄酒組 - 翡翠白葡萄酒 -&nbsp;<span style="font-family: NotoSansCJKtc-Bold">冠軍</span></p>

            </div>

            <img :class="pageFade[19][3]" style="height: 7.8vw; margin-top: 8%" src="../assets/p13.png">

          </div>

          <div class="content-img" :class='pageFade[19][1]' style="top: 0%; right: 0%">
            <img style="height: 100%" src="../assets/p16.png">

            <img class="medal" :class="pageFade[19][4]" src="../assets/p12.png">
          </div>


          <div class="arrow" @click="arrowBtn2(21)" v-if="curPage==20"></div>
        </div>

        <!--21-->
        <div class="page-content" id="page-21" v-if="curPage==21" >
          <img class="size-box" src="../assets/1x1.png">
<!--          <div class="top-title"><span class="line"></span><span class="text">PENG CHIUN DING</span><span class="line"></span></div>-->



          <div class="wine-content"  style="padding-top: 4.6%">
            <img :class="pageFade[20][2]" style="height: 25.8vw; margin: 2% 0 0 2.5%" src="../assets/p13.png">
            <div class="title" :class='pageFade[20][0]'>
              <div>
              </div>
              <div class="subtitle">卓越佳釀品質冠頂</div>
              <div class="line" style="margin-top: 1.5%"></div>
            </div>
            <div class="content" :class='pageFade[20][0]' style="width: 30%; margin-top: 1.5% ">
              <p>以品牌名「鵬群頂」三個字為概念核心，三隻鵬鳥飛翔的畫面，搭配酒莊具有歷史象徵的紅磚拱門，以負空間切出酒瓶造型。其中最大且雙翼展開的鵬鳥帶入耀眼的金色最為突出，象徵品牌飛向最頂端且具有精緻質感的最佳品質。</p>
            </div>
          </div>

          <div class="contact" :class='pageFade[20][0]'>CONTACT US<span>&nbsp;-&nbsp;聯絡我們</span></div>

          <div class="content-img" :class='pageFade[20][1]' style="top: 20.5%; right: 0%">
            <img  class="map" style="height: 60.4%; cursor: pointer" src="../assets/p14.png" @click="openUrl('https://goo.gl/maps/WDMBWnMWfc3GbPzZ6')">

            <img class="contact-box" :class="pageFade[20][3]" src="../assets/p15.png" @click="openUrl('mailto: pcdwine98837025@gmail.com')">
          </div>

        </div>
        <div class="top" @click="menuAnchor(1)" v-if="curPage==21"></div>

      </div>
    </div>



</template>

<script>
// @ is an alias to /src

import {reactive, ref, nextTick} from "vue";

export default {
  name: 'HomeView',
  components: {
  },
  setup(){
    //const count = ref(-5);
    const curPage = ref(1);
    const pageClass = ref('');
    const pageFade = reactive([]);
    const menuBtnClass = ref('');
    const menuClass = ref('scale-0');
    const allYearClass = ref('hidden');

    let scrolling = true;
    let autoHandler = -1;
    let autoTime = [
      1500,1500,1500,1500,1500,
      1500,1500,1500,1500,1500,
      0,0,0,0,0,
      0,0,0,0,0,
      0,0
    ];

    init();

    async function animationendEvent() {
      document.getElementById('page-'+curPage.value).removeEventListener('animationend', animationendEvent);

      if(curPage.value == 21){
        pageFade[19][1] = "opacity-0";
      }

      await nextTick();
      clearTimeout(autoHandler);

      if(autoTime[curPage.value] != 0){
        autoHandler = setTimeout(()=>{
          arrowBtn();
        }, autoTime[curPage.value]);
      }


      scrolling = false;
    }

    async function scrollEvent(e){
        if(scrolling){
          return;
        }

        if(e == null || (e.deltaY > 0 && curPage.value != 21)){
          //console.log("down")
          /*前10頁年份動畫*/
          if(curPage.value <= 9){
            pageFade[curPage.value-1][0] = "fade-out";
            pageFade[curPage.value-1][1] = "content-text-fade-out";
            pageFade[curPage.value][0] = "fade-in";
            pageFade[curPage.value][1] = "content-text-fade-in";
          }
          else if(curPage.value == 10){
            pageFade[curPage.value-1][0] = "slide-out";
            pageFade[curPage.value-1][1] = "content-text-slide-out";
            pageFade[curPage.value][0] = "major-text-fade-in";
            pageFade[curPage.value][1] = "major-img-slide-in";
          }
          else if(curPage.value == 11){
            pageFade[curPage.value-1][0] = "major-text-fade-out";
            pageFade[curPage.value-1][1] = "major-img-fade-out";
            pageFade[curPage.value][0] = "major-text-fade-in ";
            pageFade[curPage.value][1] = "major-img-fade-in";
            pageFade[curPage.value][2] = "";
          }
          else if(curPage.value == 12){
            pageFade[curPage.value-1][0] = "major-text-fade-out";
            pageFade[curPage.value-1][1] = "major-img-fade-out";
            pageFade[curPage.value-1][2] = "opacity-0";
            pageFade[curPage.value][0] = "major-img-fade-in";
            pageFade[curPage.value][1] = "major-img-fade-in";
            pageFade[curPage.value][2] = "";
            pageFade[curPage.value][3] = "";
          }
          else if(curPage.value == 13){
            pageFade[curPage.value-1][0] = "";
            pageFade[curPage.value-1][1] = "";
            pageFade[curPage.value-1][2] = "major-slide-out-top ";
            pageFade[curPage.value-1][3] = "opacity-0";

            pageFade[curPage.value][0] = "major-text-fade-in";
            pageFade[curPage.value][1] = "major-img-slide-in";
            pageFade[curPage.value][2] = "major-text-fade-in";
          }
          else if(curPage.value == 14){
            pageFade[curPage.value-1][0] = "major-text-fade-out";
            pageFade[curPage.value-1][1] = "major-img-fade-out";
            pageFade[curPage.value-1][2] = "opacity-0";
            pageFade[curPage.value][0] = "major-text-fade-in";
            pageFade[curPage.value][1] = "major-img-fade-in";
            pageFade[curPage.value][2] = "wine-content-up";
          }
          else if(curPage.value == 15){
            pageFade[curPage.value-1][0] = "major-text-fade-out";
            pageFade[curPage.value-1][1] = "major-img-fade-out";
            pageFade[curPage.value-1][2] = "opacity-0";
            pageFade[curPage.value][0] = "major-text-fade-in";
            pageFade[curPage.value][1] = "major-img-fade-in";
            pageFade[curPage.value][2] = "wine-content-down";
          }
          else if(curPage.value == 16){
            pageFade[curPage.value-1][0] = "";
            pageFade[curPage.value-1][1] = "";
            pageFade[curPage.value-1][2] = "major-slide-out-top";
            pageFade[curPage.value-1][3] = "opacity-0";
            pageFade[curPage.value][0] = "major-text-fade-in";
            pageFade[curPage.value][1] = "major-img-slide-in";
            pageFade[curPage.value][2] = "major-text-fade-in";
          }
          else if(curPage.value == 17){
            pageFade[curPage.value-1][0] = "major-text-fade-out";
            pageFade[curPage.value-1][1] = "major-img-fade-out";
            pageFade[curPage.value-1][2] = "opacity-0";
            pageFade[curPage.value][0] = "major-text-fade-in";
            pageFade[curPage.value][1] = "major-img-fade-in";
            pageFade[curPage.value][2] = "wine-content-down";
          }
          else if(curPage.value == 18){
            pageFade[curPage.value-1][0] = "major-text-fade-out";
            pageFade[curPage.value-1][1] = "major-slide-out-top";
            pageFade[curPage.value-1][2] = "opacity-0";
            pageFade[curPage.value-1][3] = "major-slide-out-down";
            pageFade[curPage.value][0] = "major-text-fade-in";
            pageFade[curPage.value][1] = "major-img-slide-in";
            pageFade[curPage.value][2] = "";
          }
          else if(curPage.value == 19){
            pageFade[curPage.value-1][0] = "";
            pageFade[curPage.value-1][1] = "major-slide-out-down";
            pageFade[curPage.value-1][2] = "major-slide-out-top";
            pageFade[curPage.value][0] = "";
            pageFade[curPage.value][1] = "major-img-slide-in";
            pageFade[curPage.value][2] = "major-text-fade-in";
            pageFade[curPage.value][4] = "medal-fade-in";
          }
          else if(curPage.value == 20){
            pageFade[curPage.value-1][0] = "major-slide-out-top-2";
            pageFade[curPage.value-1][1] = "major-img-slide-out";
            pageFade[curPage.value-1][3] = "major-img-fade-out";
            pageFade[curPage.value-1][4] = "major-slide-out-down";
            pageFade[curPage.value][0] = "major-text-fade-in";
            pageFade[curPage.value][1] = "major-img-slide-in";
            pageFade[curPage.value][2] = "major-img-slide-left-in";
            pageFade[curPage.value][3] = "contact-box-fade-in";
          }




          curPage.value += 1;

          scrolling = true;

          await nextTick();


          document.getElementById('page-'+curPage.value).addEventListener('animationend', animationendEvent);

        }
        else if(e.deltaY < 0 && curPage.value != 1){
          //console.log("up")

          curPage.value -= 1;

          /*前10頁年份動畫*/
          if(curPage.value <= 10){
            pageFade[curPage.value-1][0] = "fade-in";
            pageFade[curPage.value-1][1] = "content-text-fade-in";
          }
          else if(curPage.value == 10){
            pageFade[curPage.value-1][0] = "fade-in";
            pageFade[curPage.value-1][1] = "content-text-fade-in";
          }
          else if(curPage.value == 11){
            pageFade[curPage.value-1][0] = "major-text-fade-in ";
            pageFade[curPage.value-1][1] = "major-img-slide-in";
          }
          else if(curPage.value <= 13){
            pageFade[curPage.value-1][2] = "";
            pageFade[curPage.value-1][0] = "major-text-fade-in ";
            pageFade[curPage.value-1][1] = "major-img-fade-in";
            pageFade[curPage.value-1][3] = "";
            pageFade[curPage.value-1][3] = "major-text-fade-in";
          }
          else if(curPage.value == 14){
            pageFade[curPage.value-1][0] = "major-text-fade-in";
            pageFade[curPage.value-1][1] = "major-img-fade-in";
            pageFade[curPage.value-1][2] = "wine-content-down";
          }
          else if(curPage.value == 15){
            pageFade[curPage.value-1][0] = "major-text-fade-in";
            pageFade[curPage.value-1][1] = "major-img-fade-in";
            pageFade[curPage.value-1][2] = "wine-content-up";
          }
          else if(curPage.value <= 16){
            pageFade[curPage.value-1][2] = "wine-content-down";
            pageFade[curPage.value-1][0] = "major-text-fade-in ";
            pageFade[curPage.value-1][1] = "major-img-fade-in";
            pageFade[curPage.value-1][3] = "";
          }
          else if(curPage.value <= 17){
            pageFade[curPage.value-1][2] = "wine-content-up";
            pageFade[curPage.value-1][0] = "major-text-fade-in ";
            pageFade[curPage.value-1][1] = "major-img-fade-in";
          }
          else if(curPage.value == 18){
            pageFade[curPage.value-1][0] = "major-text-fade-in";
            pageFade[curPage.value-1][1] = "major-img-fade-in";
            pageFade[curPage.value-1][2] = "padding-13";
            pageFade[curPage.value-1][3] = "";
          }
          else if(curPage.value == 19){
            pageFade[curPage.value-1][0] = "";
            pageFade[curPage.value-1][1] = "major-img-slide-in";
            pageFade[curPage.value-1][2] = "major-text-fade-in";
          }
          else if(curPage.value == 20){
            pageFade[curPage.value-1][0] = "major-text-fade-in";
            pageFade[curPage.value-1][1] = "major-img-slide-in";
            pageFade[curPage.value-1][2] = "";
            pageFade[curPage.value-1][3] = "major-img-fade-in";
            pageFade[curPage.value-1][4] = "medal-fade-in";
          }



          //避免上上一個重播
          if(pageFade[curPage.value-2] != null){
              for (let i=0; i < pageFade[curPage.value-2].length; i++){
                pageFade[curPage.value-2][i] = 'opacity-0';
              }
          }


          scrolling = true;
          await nextTick();

          //pageClass.value = "";
          document.getElementById('page-'+curPage.value).addEventListener('animationend', animationendEvent);


        }
    }

    async function init(){
      pageFade.push(["fade-in", "content-text-fade-in"]);
      for (let i=0; i<21;i++)
        pageFade.push(["", "", "", "", ""]);

      await  nextTick();
      document.getElementById('page-1').addEventListener('animationend', animationendEvent);

      window.addEventListener('wheel', (e)=>{
        if(allYearClass.value == "all-year-open"){
          allYearBtn(false);
        }else{
          scrollEvent(e);
        }
      });

      window.addEventListener('touchmove', ()=>{
        let wheelEvent = new WheelEvent('wheel', {
          deltaY: 1,
          deltaMode: 1
        });

        window.dispatchEvent(wheelEvent);
        //scrollEvent(e);
      });
    }

    function arrowBtn(pageNum){

      let wheelEvent = new WheelEvent('wheel', {
        deltaY: 1,
        deltaMode: 1
      });

      window.dispatchEvent(wheelEvent);


    }

    function arrowBtn2(pageNum){
      if(pageNum==1){
        location.reload();
        return;
      }

      if(scrolling){
        return;
      }

      if(pageNum == 21){
        arrowBtn();
        return;
      }

      animationendEvent();
      for (let i=0; i<pageFade[curPage.value];i++){
        pageFade[curPage.value] = "hidden";
      }

      curPage.value = pageNum;
      scrollEvent(null);
    }

    function menuAnchor(pageNum){
      if(pageNum==1){
        location.reload();
        return;
      }

      if(scrolling){
        return;
      }

      animationendEvent();
      for (let i=0; i<pageFade[curPage.value];i++){
        pageFade[curPage.value] = "hidden";
      }

      curPage.value = pageNum;
      scrollEvent(null);

      menuBtn();
    }



    function openUrl(url){
      window.open(url, "_blank");
    }

    function menuBtn(){
      menuBtnClass.value = (menuBtnClass.value == '') ? "change" : "";

      if(menuBtnClass.value  == ''){
        menuClass.value = "menu-close";
      }else{
        menuClass.value = "menu-open";
      }
    }

    function allYearBtn(show){
      allYearClass.value = (show) ? 'all-year-open' : 'all-year-close';
      if(!show){
        setTimeout(()=>{
          allYearClass.value = 'hidden';
        }, 500);

        //回復自動撥放
        if(autoTime[curPage.value] != 0){
          autoHandler = setTimeout(()=>{
            arrowBtn();
          }, autoTime[curPage.value]);
        }
      }else{
        clearTimeout(autoHandler);
      }
    }

    return{
      //count,
      curPage,
      pageClass,
      pageFade,
      openUrl,
      menuBtn,
      menuBtnClass,
      menuClass,
      allYearClass,
      allYearBtn,
      menuAnchor,
      arrowBtn,
      arrowBtn2
    }
  },
}
</script>

<style scoped src="@/assets/home.css"></style>
<style>
body{
  //overflow: hidden;
}
</style>
